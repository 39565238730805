import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./components/Landing";
import Terms from "./components/Terms";
import AwarenessScreen from "./Screens/AwarenessScreen";
import LandingScreen from "./Screens/LandingScreen";
import { fetchApiPost } from "./utils/FetchApi";
import { ApiName } from "./utils/ApiName";
import TagManager from "react-gtm-module";

function App() {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId:"GTM-KK3X89FP",
    };
    TagManager.initialize(tagManagerArgs);
    handleGenerateToken();
  }, []);

  // handleGenerateToken
  const handleGenerateToken = async () => {
    try {
      let data = {
        username: process.env.REACT_APP_UserName,
        password: process.env.REACT_APP_Password,
      };
      console.log(data)
      const response = await fetchApiPost("", `${ApiName.generateToken}`, data);
      if (response.status === 0) {
        setToken(response.response.token);
      }else{
        console.log("Something went wrong")
      }
    } catch (error) {
      console.log("handleGenerateToken", error);
    }
  };
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/landing" element={<LandingScreen token={token}  />} />
          <Route path="/awareness" element={<AwarenessScreen token={token} />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/*" element={<LandingScreen token={token}/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
